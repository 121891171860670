.pagination {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  color: white;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-link:hover {
  background-color: #ddd;
}

.page-item.disabled .page-link {
  background-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.blogtext {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  color: var(--light-gray);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

